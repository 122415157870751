@mixin box-sizing() {
	box-sizing: content-box;
	&::before,
	&::after {
		box-sizing: content-box;
	}
	* {
		box-sizing: content-box;
		&::before,
		&::after {
			box-sizing: content-box;
		}
	}
}

@mixin reset() {
	font: inherit;
	font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	vertical-align: baseline;
}
